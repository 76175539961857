.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container
{
  padding: 3.5rem;
  background-color:#3b2e4d;
}
#calendar
{
  background-color: white;
  padding: 1.5rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.fc-button-primary
{
  background-color: rgb(59, 46, 77) !important;
  border: 0px !important;
}
.btn-primary
{
  background-color: rgb(59, 46, 77) !important;
  border: 0px !important;
}
.btn-primary:hover
{
  background-color: rgb(128 119 139) !important
}
.button-danger
{
  background-color: #c22424 !important;
  border: 0px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.button-danger:hover
{

  background-color: #f22424 !important;
  border: 0px !important;
}
.toolbar
{
  background-color: white;
  width: 100%;
  min-height: 10rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.color-square
{
  width: 2rem;
  margin: 0.5rem;
  height:2rem;
  border-radius: 1rem;
}
.color-square:hover
{
  cursor: pointer;
}

.plusIcon:hover
{
  cursor: pointer;
  color: grey;

}

.plusIcon
{
  font-size: 3.5rem;
  color: white;
  margin-top: 1rem;
}

.top-form-sec
{
  padding-top: 2rem;
  background-color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.bottom-form-sec
{
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.form-sec
{
  background-color: white;
  padding-top: 2rem;
}

.backButton:hover
{
  cursor: pointer;
}

.campaignCard
{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-width: 8px 8px 8px 8px;
  border-color: #3b2e4d;
  border-style: solid;
  margin-bottom: 0.1rem;
  background-color: #3b2e4d;
  font-size: 0.9rem;
  color: white;
}

.campaignCell
{
  text-align: left;
  padding: 0.5rem;
  color: white;
  border-right: 2px solid white;
}

.linkText
{
  margin-top: 1rem;
  text-decoration: none;
}

.linkText > a
{
  color: white;
  text-decoration: none;
}
.linkText > a:hover
{
  color: white;
  text-decoration: none;
  text-decoration-line: underline;
}
